import * as React from "react"
//import { Link, graphql } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react";
//import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import { Autoplay } from "swiper";
//import 'swiper/css/navigation';
//import 'swiper/css/pagination';

const TrustedBy = ({ title, items, ...props }) => {

  return (


    <div className="pt-11  bg-[#F5F9FF]">
      <div className="container text-center ">
        <h4 className="font-normal mb-11 lg:text-subtitle-light text-subtitle-reg-mobile lg:mt-4">{title}</h4>

        <div className="hidden lg:block">
          <div className="flex flex-col lg:flex-row items-center	 justify-around lg:justify-center  lg:mx-12 lg:space-x-16	">
            {items.map((e, index) => (
              <div key={index} className="mb-8 lg:mb-0 ">
                <img src={e.relationships.image.localFile.publicURL} alt={e.image.alt} width={e.image.width} height={e.image.height} className="block " loading="lazy" />
              </div>
            ))}
          </div>
        </div>

        <div className="lg:hidden mt-12">
          <LogoMatrixMobile items={items} />
        </div>
      </div>
    </div>



  )
}


export const TrustedBySwiper = ({ items, ...props }) => {

  return (
    <Swiper
      modules={[Autoplay]}
      spaceBetween={10}
      slidesPerView={2.5}
      autoplay={true}
    >


      {items.map((e, index) => (
        <SwiperSlide className="text-center" key={index}>
          <div key={index}>
            <img src={e.relationships.image.localFile.publicURL} alt={e.relationships.image.alt} loading="lazy" />
          </div>
        </SwiperSlide>
      ))}


    </Swiper>

  )
}


const LogoMatrixMobile = ({ items }) => {

  return (

    <div className="grid grid-cols-2 gap-2  gap-y-12 items-center ">
      {items.map((e, index) => (
        <div key={index} className="mr-auto ">
          <img src={e.relationships.image.localFile.publicURL} alt={e.image.alt} width={e.image.width} height={e.image.height} className="" loading="lazy" />
        </div>
      ))}
    </div>
  )
}

export const TrustedBySimple = ({ title, items, className = '', h4ClassName = '', ...props }) => {

  return (
    <div className={className}>
      <div>
        <h4 className={"font-normal lg:text-subtitle-light text-subtitle-reg-mobile " + h4ClassName}>{title}</h4>
        <div className="hidden lg:block">
          <div className="flex -flex-wrap	flex-row items-center justify-around lg:justify-start  space-y-4 space-x-4 lg:space-x-8  xl:space-x-12 ">
            {items.map((e, index) => (
              <div key={index} >
                <img src={e.relationships.image.localFile.publicURL} alt={e.image.alt} loading="lazy" />
              </div>
            ))}
          </div>
        </div>


        <div className="lg:hidden ">
          <LogoMatrixMobile items={items} />
        </div>

      </div>
    </div>



  )
}


export default TrustedBy
