import { graphql, useStaticQuery } from "gatsby"

const useTestimonialsBlock = () => {

  const data = useStaticQuery(graphql`
    query {
      testimonial: blockContentTestimonial(drupal_id: {eq: "9f5023f6-6831-433d-b0d1-289450fecb61"}) {
        relationships {
          items:field_items {
            name:field_label
            jobTitle:field_slug
            company:field_title
            descriptionHtml: field_description_html {
              processed
            }
            alt:field_image {
              alt
              width
              height
            }
            logo_alt:field_logo{
              alt
              width
              height
            }
            logo_selected:field_logo_selected{
              alt
              width
              height
            }
            relationships {
             image: field_image {
              ...FaceImage
             }
             logo: field_logo{
              ...Image
             }
             logo_selected: field_logo_selected{
              ...Image
             }
            }
          }
        }
      }
    }
  `)


  //console.log(data)

  //console.log(data.testimonial.relationships.items)

  const res = data.testimonial.relationships.items.map(ele => ({

    label: ele.name,
    slug: ele.jobTitle,
    company: ele.company,
    descriptionHtml: ele.descriptionHtml.processed,
    relationships: ele.relationships,
    image: {
      alt: ele?.alt?.alt,
      width: ele?.alt?.width,
      height: ele?.alt?.height
    },
    logo: {
      alt: ele?.logo_alt?.alt,
      width: ele?.logo_alt?.width,
      height: ele?.logo_alt?.height
    },
    logo_selected: {
      alt: ele?.logo_selected?.alt,
      width: ele?.logo_selected?.width,
      height: ele?.logo_selected?.height
    }

  }))

  //console.log(res)

  return res
}

export default useTestimonialsBlock
