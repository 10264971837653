import /* React, */ { useEffect, useState } from "react";

const useViewportWidth = () => {
  const [width, setWidth] = useState(0);
  //const [hasWidth, setHasWidth] = useState(false);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    handleWindowSizeChange()
    //setHasWidth(true)
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return width;
}

export default useViewportWidth