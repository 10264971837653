import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
//import Layout2 from "../components/layout2";
//import Image from "../components/Image";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import HubspotForm from "react-hubspot-form";
import { TrustedBySimple } from "../components/trusted-by";
//import { relativeToAbsulote } from "../util/common";
import TestimonialsSlideshowHp from "../components/testimonials-slideshow2";
import { Teasers } from "../components/resources";
//import { /* Header, */ LogoHeader } from "../components/header";
import { Link } from "gatsby";
import logo from "../images/Logo.svg";
import useViewportWidth from "../hooks/useViewportWidth";

function BookADemo({
  data: { nodeBookADemo },
  //pageContext: { isThankYou },
  //...props
}) {
  const { path, trustedByTitle, isCalendarMode, relationships, calendarUrl, title1, title2 } =
    nodeBookADemo;

  const {
    left,
    hubspot,
    //thankyou,
    trustedBy,
    box1,
    video,
    video_thumb,
    resources,
  } = relationships;

  const seo = getMetatags(
    nodeBookADemo.metatag,
    nodeBookADemo.relationships.OGImage?.localFile?.publicURL
  );

  seo.meta.push({
    name: "robots",
    content: "noindex, nofollow",
  });

  left.trustedBy = trustedBy;
  left.trustedByTitle = trustedByTitle;
  left.title3 = title1;

  video_thumb.alt = nodeBookADemo.video_thumb.alt;

  const width = useViewportWidth();
  const isMobile = width <= 768;

  
  return (
    <main className="xl:bg-[url('/background/book-a-demo-2.png')] bg-repeat-y bg-right-top  h-full min-h-screen w-full">
      <Seo {...seo} />
      <header className="container py-8 w-full">
        <div className="container flex justify-between">
          <div className="">
            <Link to="/">
              <img src={logo} alt="sightfull" className="inline" />
            </Link>
          </div>
        </div>
      </header>

      <div className="container flex flex-col xl:flex-row w-full h-full min-h-screen justify-between px-0 lg:px-6">
        <div className="xl:w-7/12 h-full min-h-screen ">
          <div className="container space-y-8 lg:space-y-16 py-4 lg:py-0 xl:mb-32">
            <Main left={left} />
            <Box1 title={title1} items={box1} />
            <Video title={title2} video={video} thumb={video_thumb} />
            <div className="xl:hidden">
              {!isCalendarMode && <Side {...hubspot} path={path.alias} />}
              {isCalendarMode && <SideCalendar url={calendarUrl} />}
            </div>
            <TrustedBy left={left} />
            <div className="xl:h-4"></div>
            <Testimonials width={width} isMobile={isMobile} />
            <div className="xl:h-8"></div>
            <Resources resources={resources} />
            {/* <div className="xl:hidden fixed bottom-0 left-0 right-0 bg-white px-6 py-4 bg-white z-[1001]">
              <a href="#the-form" className="p-3 w-full block text-center bg-blue text-white border rounded-full font-bold">
                Book A Demo
              </a>
            </div> */}
          </div>
        </div>
        <div
          className={"xl:w-4/12 text-white min-h-screen lg:p-0  lg:pt-0  hidden xl:block  "}
        >
          <div className="">
            {!isCalendarMode && <Side {...hubspot} path={path.alias}  />}
            {isCalendarMode && <SideCalendar url={calendarUrl} />}
          </div>
        </div>
      </div>
    </main>
  );

}

const Main = ({ left }) => {
  return (
    <div className="">
      <h1
        className="text-blue text-2xl 2xl:text-subtitle-l-bold font-bold  "
        dangerouslySetInnerHTML={{ __html: left.title }}
      ></h1>
    </div>
  );
};

const Box1 = ({ title, items }) => {

  const blocks = items.map((item, index) => (
    <div className="flex flex-row space-x-4" key={index}>
      <div           className="min-w-[50px]">
        <img
          src={item.relationships.image.localFile.publicURL}
          alt={item.image.alt}
          className="min-w-[50px]"
        />
      </div>
      <div className="leading-[28px]">{item.title}</div>
    </div>
  ));
  return (
    <div className="space-y-4 lg:space-y-8 ">
      <div className="text-base lg:text-xl lg:leading-8 pb-2">{title}</div>
      <div className="grid lg:grid-cols-2 gap-x-16 gap-y-4 lg:gap-y-8 ">
        {blocks}
      </div>
    </div>
  );
};

const Video = ({ title, video, thumb }) => {
  const [show, setShow] = useState(false);

  const img = (
    <button onClick={() => setShow(true)} className="">
      <img src={thumb?.localFile?.publicURL} alt={thumb.alt} />
    </button>
  );

  const vid = (
    /* eslint-disable-next-line jsx-a11y/media-has-caption */
    <video width="440" controls className="text-center mx-auto">
      <source src={video?.localFile?.publicURL} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );

  return (
    <div className="mx-auto text-center  space-y-7">
      <h2 className="font-medium">{title}</h2>
      {show ? vid : img}
    </div>
  );
};

const TrustedBy = ({ left }) => {
  return (
    <div className="">
      <TrustedBySimple
        items={left.trustedBy}
        title={left.trustedByTitle}
        className="lg:py-0 lg:pb-0"
        h4ClassName="mb-10 "
      />
    </div>
  );
};

const Testimonials = ({ width, isMobile }) => {

  const showTestimonials = true;

  return (
    <div className="">
      {width !== 0 && showTestimonials && (
        <div className="relative">

          <div className="hidden lg:block absolute top-0 right-[-4rem]">
            <img src="/background/quote-mark-right.svg" alt="quote mark" />
          </div>


          <div className="hidden lg:block absolute bottom-0 left-[-10rem]" >
            <img src="/background/quote-mark-left.svg" alt="quote mark" />
          </div>

          
          <TestimonialsSlideshowHp
            title={/*testimonialsTitle*/ "Why choose Sightfull?"}
            isMobile={isMobile}
            isHomepage={false}
            className="bg-[#F1F7FE] -mx-6 mx-0 px-4 xl:px-0 xl:bg-white xl:ml-[-8rem] xl:py-0   "
            classNameSection="px-0 lg:px-4 lg:space-y-8 "
            classNameTitle="text-[35px] lg:ml-[7.25rem] text-center lg:text-left"
            classNameCard="lg:w-10/12"
          />
        </div>
      )}
    </div>
  );
};

const Resources = ({ resources }) => {
  return (
    <div className="">
      {/* <h2 className="text-[35px] font-bold">More Resources</h2> */}
      <Teasers
        items={resources.map((r) => ({ ...r, type: "Video" }))}
        classNameSection="py-4 lg:py-0"
        classNameGridContainer=""
        classNameGrid="lg:grid-cols-2 w-full "
      />
    </div>
  );
};

const Side = ({ portal_id, form_id, title, sub_title, path }) => {

  const ready = (form) => {
    
    var containers = document.getElementsByClassName(
      "legal-consent-container"
    );

    for (var x = 0 ; x < containers.length ; x++  ) {
      var container = containers[x]

      var fieldset1 = container.closest("fieldset");
      var f = container.closest("form");
      
      f.append(fieldset1)

    };
    
  };

  // const swapElements = (elm1, elm2) => {
  //   var parent1, next1, parent2, next2;

  //   parent1 = elm1.parentNode;
  //   next1 = elm1.nextSibling;
  //   parent2 = elm2.parentNode;
  //   next2 = elm2.nextSibling;

  //   parent1.insertBefore(elm2, next1);
  //   parent2.insertBefore(elm1, next2);
  // };

  return (
    <div className="xl:fixed right-24 xl:right-auto">
      <div className="container p-0 lg:p-0 ">
        <div className="bg-white text-grey  rounded-xl py-10 px-4 xl:px-10 shadow-[0_3px_20px_rgba(0,0,0,0.1)] max-w-fit xl:mr-8 xl:max-w-[600px] space-y-3">
          <h2 className="text-blue  text-2xl lg:subtitle-m-bold font-bold  text-center" id="the-form">
            {title}
          </h2>
          <p className=" text-sm text-center">{sub_title}</p>
          <HubspotForm
            portalId={portal_id}
            formId={form_id}
            //cssRequired=""
            css=" " // !important
            cssClass="sigthfull-hubspot"
            submitButtonClass="sigthfull-hubspot-submit-btn c-btn  "
            errorClass="sigthfull-hubspot-error"
            errorMessageClass="sigthfull-hubspot-error-message"
            //onSubmit={(e) => console.log('Submit!')}
            //onFromSubmit={(e) => console.log('on form submit')}
            //onFormSubmitted={(e) => console.log('on onFormSubmitted')}
            onReady={ready}
            //loading={<div>Loading...</div>}
            redirectUrl={path + "/thank-you"}
          />
        </div>
      </div>
    </div>
  );
};

const SideCalendar = ({ url }) => {


  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.onload = function (ev) {
      console.log("script loaded!!");
    };
    script.onerror = function (ev) {
      console.log("script failed to load." + ev);
    };
    document.body.appendChild(script);
  }, []);

  return (
    <div>
      <div
        className="bg-white border pt-6 meetings-iframe-container h-[670px] border"
        //data-src="https://meetings-eu1.hubspot.com/sightfull?embed=true"
        data-src={url+"?embed=true"}
      ></div>
    </div>
  );

  // return (
  //   <div className="">
  //     <iframe title="Calendar" src={url} width={'100%'} height={'810px'} className="" />
  //   </div>
  // );
};

export default BookADemo;

export const query = graphql`
  query bookADemo2a($id: String!) {
    nodeBookADemo(id: { eq: $id }) {
      id
      title
      trustedByTitle: field_title2
      path {
        alias
      }
      showCTA: field_show_book_demo

      testimonialShow: field_show_testimonials
      testimonialTitle: field_title_5
      isCalendarMode: field_use_calendar
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      calendarUrl: field_title
      title1: field_title3
      title2: field_title4
      video_thumb: field_video_thumb {
        alt
      }
      relationships {
        left: field_hero {
          ...ParagraphGenericBlock
        }
        thankyou: field_paragraph_single {
          ...ParagraphGenericBlock
        }
        hubspot: field_hubspot {
          ...ParagraphHubspotContent
        }
        trustedBy: field_paragraph_repeter {
          image: field_image {
            title
            alt
          }
          relationships {
            image: field_image {
              uri {
                url
              }
              localFile {
                url
                publicURL
              }
            }
          }
        }
        box1: field_paragraph_repeater2 {
          ...ParagraphImageAndText
        }
        box2: field_items {
          ...ParagraphImageAndText
        }
        video: field_video_file {
          localFile {
            publicURL
          }
        }
        video_thumb: field_video_thumb {
          localFile {
            publicURL
          }
        }
        resources: field_more_resources {
          id
          title

          date: field_date
          image: field_hero_image {
            alt
            height
            width
          }
          relationships {
            image: field_hero_image {
              localFile {
                publicURL
              }
            }
            video: field_video_file {
              localFile {
                publicURL
              }
            }
          }
        }
        OGImage: field_image {
          localFile {
            url
            publicURL
          }
        }
      }
    }
  }
`;
